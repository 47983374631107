<template>
  <v-text-field
    class="border-radius"
    ref="input"
    dense
    hide-spin-buttons
    :background-color="bgColor"
    outlined
    :value="value"
    :label="label"
    :placeholder="placeholder"
    :height="height"
    :rules="showRules ? enableValidateRules : []"
    @input="updateValue($event)"
    @blur="blueHandler()"
    @click="$emit('click')"
    @click.native="$emit('click.native')"
    @keyup="$emit('keyup', value)"
    @keydown.enter.prevent
    :prefix="prefix"
    :type="type"
    :error="error"
    :error-messages="errorMessage"
    :append-icon="icon || appendIcon"
    :min="min"
    @click:append="showPassword = !showPassword"
    :disabled="disabled"
    :readonly="readonly"
    :messages="message"
    :autofocus="autofocus"
    :color="color"
    :hide-details="hideDetails"
    :key="inputKey"
    :clearable="clearable"
  />
</template>

<script>
/** Компонент ввода текстового поля, также подходит для полей пароля */
import { mapState } from "vuex";
import Vue from "vue";

export default Vue.extend({
  name: "TextInput",
  props: {
    /** Значение поля, обязательные параметр*/
    value: {
      required: true,
    },
    /** label поля*/
    label: {
      type: String,
    },
    /** placeholder поля*/
    placeholder: {
      type: String,
    },
    /** Высота поля*/
    height: {
      type: String,
      default: "42",
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    /**
     * Правила валидации
     * @values required, min, email, phone, string
     */
    rules: {
      type: Array,
    },
    /** Флаг для обозначения, что данное поле для пароля*/
    isPassword: {
      type: Boolean,
      default: false,
    },
    /** Массив с двумя паролями для их сравнения*/
    passComparison: {
      type: Array,
    },
    /** Задает цвет фона поля*/
    bgColor: {
      type: String,
    },
    /** Поле для передачи текста сообщения об ошибке*/
    errorMessage: {
      type: String,
    },
    /** Флаг, вызывает подсветку ошибки поля */
    error: {
      type: Boolean,
    },
    /** Флаг для обозначения, что данное поле числовое*/
    isNumber: {
      type: Boolean,
      default: false,
    },
    /** Максимальное значение для числового ввода */
    max: {
      type: String,
    },
    /** Устанавливает минимальное значение для числового поля*/
    min: {
      type: String,
    },
    /** Флаг блокировки поля*/
    disabled: {
      type: Boolean,
      default: false,
    },
    /** Флаг - поле только для чтения (своего рода блокировка)*/
    readonly: {
      type: Boolean,
      default: false,
    },
    /** Символ в начале поля ввода */
    prefix: {
      type: String,
    },
    /** Устанавливает сообщение под поле */
    message: {
      type: String,
    },
    /** Устанавливает фокус на поле */
    autofocus: {
      type: Boolean,
    },
    /** Задает цвет поля */
    color: {
      type: String,
    },
    /** Флаг, устанавливает спец класс на корневой input компонента */
    yandex: {
      type: Boolean,
    },
    customStyles: {
      type: Boolean,
      default: false,
    },
    appendIcon: {
      type: String,
      default: "",
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      /** Флаг, показывать пароль или нет*/
      showPassword: false,
      /** в случае если поле-пароль, устанавливается кнопка-иконка для срытия/показа пароля*/
      icon: "",
      /** Тип поля, текстовый или пароль*/
      type: "text",
      showRules: true,
      /** Правила валидации*/
      validateRules: {
        required: (v) => v !== "" || "Обязательное поле",
        password: (v1, v2) => v1 === v2 || "Пароли должны совпадать",
        email: (v) => {
          const pattern = /^[a-zA-Zа-яА-Я0-9][^\s]*@[^\s]+\.[^\s]+$/;
          return ((!v && v !== "" )|| pattern.test(v)) || "Некорректный e-mail";
        },
        phone: (v) => {
          const pattern = /\(\d{3}\)\d{3}-\d{2}-\d{2}/;
          return pattern.test(v) || "Некорректный номер телефона";
        },
        phoneNumber: (v) => {
          let pattern =
            /^(\+7|7|8)?[\s\-]?\(?[01234569][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/; // eslint-disable-line
          return ((!v && v !== "" ) || pattern.test(v)) || "Некорректный номер телефона";
        },
        string: (v) => {
          const pattern = /^[а-яА-ЯёЁ ]+$/g;
          return pattern.test(v) || "Только русские буквы";
        },
        number: (v) => {
          const pattern = /^\d+$/;
          return pattern.test(v) || "Только цифры";
        },
        numberMin: (v) => {
          const pattern = /^[0-9]\d*$/;
          return pattern.test(v) || "Не может быть отрицательным";
        },
        min: (v) => v.length >= 6 || "Минимум 6 символов",
        max: (v) => v <= +this.max || `Максимальное значение: ${this.max}`,
        minValue: (v) => v >= +this.min || `Минимальное значение: ${this.min}`,
        promoCode: (v) => {
          const pattern = /^[A-Za-z0-9]+/;
          return pattern.test(v) || "Только цифры и английские буквы";
        },
        cadastralNumber: (v) => {
          const pattern = /\d{1,2}:\d{1,2}:\d{6,7}:\d{1,4}/;
          return pattern.test(v) || "Неверный формат";
        },
        useBonus: (v) =>
          v <= this.user.bonuses || "Указанное значение больше доступного",

        /** Дробные числа **/
        fractionalNumber: (v) => {
          if (this.rules.includes("required") || v) {
            const pattern = /^\d+(?:[\.,]\d+)?$/;
            return pattern.test(v) || "Только цифры";
          }
          return true;
        },
      },
      /** Включенные правила валидации **/
      enableValidateRules: [],
      inputKey: 0,
    };
  },

  methods: {
    /**
     * Обновляет значение поля
     * @param val актуальное значение
     */
    updateValue(val) {
      this.$emit("input", val);
      this.showRules = true;
    },
    blueHandler() {
      this.$emit("blur");
      if (!this.rules?.includes("required") && !this.value.length) {
        this.showRules = false;
      }
    },

    /**
     * Применение правил валидации к полю
     * @returns {*[]} возвращает массив функций
     */
    enableRules() {
      this.enableValidateRules = [];
      this.inputKey++;
      Object.keys(this.validateRules).forEach((rule) => {
        if (this.rules !== undefined && this.rules.includes(rule)) {
          this.enableValidateRules.push(this.validateRules[rule]);
        }
      });

      if (this.isNumber && this.max !== undefined) {
        this.enableValidateRules.push(this.validateRules.max(+this.value));
      }

      if (this.passComparison !== undefined) {
        this.enableValidateRules.push(
          this.validateRules.password(
            this.passComparison[0],
            this.passComparison[1]
          )
        );
      }
    },
  },

  computed: {
    ...mapState("user", ["user"]),
  },

  mounted() {
    this.enableRules();
    /** Если стоит флаг yandex, то устанавливает класс метрики на корневой input */
    if (this.yandex) {
      this.$refs.input.$el
        .querySelector("input")
        .classList.add("ym-disable-keys");
    }

    /** В случае если поле - пароль, задает тип поля и устанавливает картинку*/
    if (this.isPassword) {
      this.icon = "mdi-eye-off";
      this.type = "password";
    }

    /** В случае если поле числовое, задает тип поля*/
    if (this.isNumber) {
      this.type = "number";
    }
  },

  watch: {
    /** Смотрит за изменение флага и отображает или скрывает пароль с изменением иконки*/
    showPassword() {
      if (this.isPassword) {
        if (this.showPassword) {
          this.icon = "mdi-eye";
          this.type = "text";
        } else {
          this.icon = "mdi-eye-off";
          this.type = "password";
        }
      }
    },
    min() {
      this.enableRules();
    },
    max() {
      this.enableRules();
    },
  },
});
</script>
<style lang="scss">
.v-input--dense > .v-input__control > .v-input__slot {
  margin-bottom: 1px;
}
</style>

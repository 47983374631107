import { optionTypes } from "@/calculator/shared/Classes/BankClass";
import { testExcludedParams, testDopParams, } from "@/shared/scripts/TestAdsParams";
export const calcGetters = {
    getRate: (state, getters) => (id, rate = "minRate") => {
        const bank = state.banks[id];
        let calculatedRate = bank.fixedRate.rate !== 0 ? bank.fixedRate.rate : bank[rate];
        [...optionTypes.plus].forEach((type) => {
            if (getters.getIsConsumerCredit && type === "insuranceLife") {
                if (bank["minRate"] !== bank["maxRate"]) {
                    bank[type].applied
                        ? (calculatedRate +=
                            rate === "minRate"
                                ? bank[type].minValue
                                : bank[type].maxValue)
                        : (calculatedRate += 0);
                }
                else {
                    bank[type].applied
                        ? (calculatedRate += bank[type].maxValue)
                        : (calculatedRate += 0);
                }
            }
            else {
                bank[type].applied
                    ? (calculatedRate += bank[type].value)
                    : (calculatedRate += 0);
            }
        });
        [...optionTypes.minus].forEach((type) => {
            bank[type].applied
                ? (calculatedRate -= bank[type].value)
                : (calculatedRate += 0);
        });
        return Number(calculatedRate)
            .toFixed(2)
            .replace(/0*$/, "")
            .replace(/\.$/, "");
    },
    getFullCreditSum(state, getters) {
        const inputsValues = getters.inputsValues;
        /** Для потребов сумма кредита - поле с id 18**/
        if (getters.getIsConsumerCredit) {
            return inputsValues[18];
        }
        const creditSum = inputsValues[151] !== undefined ? inputsValues[151] : 0;
        const creditType = inputsValues[-1] !== undefined ? inputsValues[-1] : 0;
        const refOptions = inputsValues[414] !== undefined ? inputsValues[414] : 0;
        const addSum416 = inputsValues[416] !== undefined ? inputsValues[416] : 0;
        const addSum425 = inputsValues[425] !== undefined ? inputsValues[425] : 0;
        let fullCreditSum;
        if (creditType === 1182) {
            if (refOptions === 1184) {
                fullCreditSum = creditSum + addSum416;
            }
            else if (refOptions === 1185) {
                fullCreditSum = creditSum + addSum425;
            }
            else if (refOptions === 1186) {
                fullCreditSum = creditSum + addSum425 + addSum416;
            }
            else if (refOptions === 0) {
                fullCreditSum = creditSum;
            }
        }
        else {
            fullCreditSum = creditSum;
        }
        return fullCreditSum;
    },
    getCreditTerm(state, getters) {
        let creditTerm;
        const inputsValues = getters.inputsValues;
        if (getters.getIsConsumerCredit) {
            creditTerm = inputsValues[17];
        }
        else {
            creditTerm = inputsValues[153] !== undefined ? inputsValues[153] : 0;
        }
        return creditTerm;
    },
    getPaymentByRate: (_, getters) => (rate) => {
        const fullCreditSum = getters.getFullCreditSum;
        let creditTerm = getters.getCreditTerm;
        return Math.ceil(fullCreditSum * (rate / (1 - (1 + rate) ** -creditTerm)));
    },
    getCalculatedPayment: (_, getters) => (id, rate = "minRate") => {
        const fullCreditSum = getters.getFullCreditSum;
        const monthRate = Number(getters.getRate(id, rate)) / 100 / 12;
        const inputsValues = getters.inputsValues;
        if (inputsValues[-1] === 2515) {
            return (Number(getters.getRate(id, rate)) / 100) * fullCreditSum;
        }
        return getters.getPaymentByRate(monthRate);
    },
    getCalculatedIncome: (state, getters) => (id, rate = "minRate") => {
        const payment = getters.getCalculatedPayment(id, rate);
        const financialBurden = state.financialBurden;
        const banks = state.banks;
        const currentCredits = financialBurden["monthlyLoanPayments"] !== undefined
            ? financialBurden["monthlyLoanPayments"]
            : 0;
        const creditCardLimit = financialBurden["limitOfActiveCreditCards"] !== undefined
            ? financialBurden["limitOfActiveCreditCards"]
            : 0;
        const childCount = financialBurden["numberOfDependents"] !== undefined
            ? financialBurden["numberOfDependents"]
            : 0;
        const creditCardLimitPercent = banks[id].creditCardLimitPercentMax;
        const childOutcome = banks[id].childOutcomeMax;
        const paymentPercent = banks[id].paymentPercent;
        const notTakeAccountCreditCardLimitUpTo = banks[id].notTakeAccountCreditCardLimitUpTo;
        const creditCardPayments = creditCardLimit < notTakeAccountCreditCardLimitUpTo
            ? 0
            : (creditCardLimit * creditCardLimitPercent) / 100;
        const minimumLifeCost = 15000;
        const calc1 = Math.ceil((100 * (payment + currentCredits + creditCardPayments)) / paymentPercent);
        const calc2 = Math.ceil(payment +
            currentCredits +
            creditCardPayments +
            minimumLifeCost +
            childCount * childOutcome);
        return Math.max(calc1, calc2);
    },
    getCheckedPromoCount: (state) => (id) => {
        if (state.banks[id] !== undefined) {
            return state.banks[id].countDiscount;
        }
    },
    showCommissionAndPreferenceIcon: (state) => (id) => {
        return (state.showRewardUser &&
            state.showPreferencesUser &&
            (state.banks[id]?.myCommission?.minValue > 0 ||
                state.banks[id]?.myCommission?.maxValue > 0 ||
                state.banks[id]?.commission?.minValue > 0 ||
                state.banks[id]?.commission?.maxValue > 0 ||
                state.banks[id]?.preferences?.value > 0 ||
                state.banks[id]?.myPreferences?.value > 0));
    },
    getIsConsumerCredit(state, getters) {
        const inputsValues = getters.inputsValues;
        return (inputsValues[-1] === 2514 ||
            inputsValues[-1] === 2515 ||
            inputsValues[-1] === 2516 ||
            inputsValues[-1] === 2801);
    },
    getIsObjectLand(state, getters) {
        const inputsValues = getters.inputsValues;
        return !!inputsValues[389];
    },
    getIsAllRegions(state, getters) {
        const inputsValues = getters.inputsValues;
        return inputsValues[0] === 28;
    },
    getIsCreditCardCredit(state, getters) {
        const inputsValues = getters.inputsValues;
        return inputsValues[-1] === 2515;
    },
    getIsIslamicCredit(state, getters) {
        const inputsValues = getters.inputsValues;
        return inputsValues[382] === 2755 || inputsValues[-1] === 2758; // тип кредита === Исламская ипотека || тип кредита для потребов === Исламская дебетовая карта
    },
    getIsIslamicDebitCard(state, getters) {
        const inputsValues = getters.inputsValues;
        return inputsValues[-1] === 2758; // тип кредита для потребов === Исламская дебетовая карта
    },
    choosedBanksCalculator(state) {
        return state.choosedBanks;
    },
    calculatorInputs(state) {
        return state.categories
            .map((el) => el.inputs)
            .reduce((acc, category) => {
            return acc.concat(category);
        }, []);
    },
    inputsValues(state, getters) {
        const inputs = getters.calculatorInputs;
        const value = {};
        inputs.forEach((input) => {
            value[input.id] = input.value;
        });
        return value;
    },
    getTypesLoan(state) {
        return state.typesLoan;
    },
    getAppliedPromos(state) {
        const appliedPromos = [];
        Object.values(state.banks).forEach((el) => {
            if (el.promoActive > 0) {
                const promos = Object.values(el.promo)
                    .filter((promo) => promo.applied)
                    .map((promo) => promo.id);
                appliedPromos.push({
                    bankId: el.id,
                    promoIds: promos,
                    salaryProject: el.salaryProject.applied,
                });
            }
        });
        return appliedPromos;
    },
    getDataForReport: (state, getters) => (formData, banks) => {
        const inputsValues = getters.inputsValues;
        let creditTerm;
        if (getters.getIsConsumerCredit) {
            creditTerm = inputsValues[17];
        }
        else {
            creditTerm = inputsValues[153] !== undefined ? inputsValues[153] : 0;
        }
        return {
            ...formData,
            creditSum: getters.getFullCreditSum,
            creditTerm: creditTerm,
            banks: banks.map((bank) => {
                return {
                    id: bank.id,
                    name: state.banks[bank.id].name,
                    effectiveRateMin: state.banks[bank.id].effectiveRateMin,
                    effectiveRateMax: state.banks[bank.id].effectiveRateMax,
                    minRate: state.banks[bank.id].minRate,
                    maxRate: state.banks[bank.id].maxRate,
                    minIncome: getters.getCalculatedIncome(bank.id, "minRate"),
                    maxIncome: getters.getCalculatedIncome(bank.id, "maxRate"),
                    minPayment: getters.getCalculatedPayment(bank.id, "minRate"),
                    maxPayment: getters.getCalculatedPayment(bank.id, "maxRate"),
                    top: bank.top,
                };
            }),
        };
    },
    getDataForLead: (state, getters) => (isConstructionCompany = false, id) => {
        if (isConstructionCompany) {
            return {
                parameters: state.parameters,
                id: id,
            };
        }
        else {
            const currentBank = state.banks[id];
            return {
                parameters: state.parameters,
                bankParams: {
                    id: currentBank.idMarketplace,
                    promo: Object.values(currentBank.promo)
                        .filter((el) => el.applied)
                        .map((el) => el.id),
                    salaryProject: currentBank.salaryProject.applied,
                    insuranceLife: currentBank.insuranceLife.applied,
                    insuranceTitle: currentBank.insuranceTitle.applied,
                    ac_id: getters.getBankProductInfo(currentBank.idMarketplace)?.id,
                },
            };
        }
    },
    getIncompatibilityReducedRateWithPromos: (_) => (paidRateReduction, bank) => {
        //проверка на совместимость с акциями
        if (bank.promoActive > 0 &&
            (paidRateReduction.incompatibility_with_auto_promos ||
                paidRateReduction.incompatibility_with_manual_promos)) {
            const appliedPromos = Object.values(bank.allPromo).filter((promo) => promo.applied);
            let appliedHandlerPromos = [];
            let appliedAutoPromos = [];
            appliedPromos.forEach((el) => {
                (el.handler === 1 ? appliedHandlerPromos : appliedAutoPromos).push(el);
            });
            return !((paidRateReduction.incompatibility_with_auto_promos &&
                appliedAutoPromos.length) ||
                (paidRateReduction.incompatibility_with_manual_promos &&
                    appliedHandlerPromos.length));
        }
        else {
            return true;
        }
    },
    getShowTemplates(state) {
        return !!(state.templates.publicTemplates.length ||
            state.templates.selfTemplates.length ||
            state.templates.companyTemplates.length);
    },
    getBankLogoInfo2: (state) => (bankId) => {
        return state.banksLogos[bankId];
    },
    getIsBankWithFeedBack: (state, getters) => (bankId) => {
        const productId = state.parameters[-1]?.value;
        if (productId) {
            return !!getters.getBankProductInfo(bankId);
        }
        else {
            return !!state.bankAdvertises[bankId];
        }
    },
    getBankProductInfo: (state) => (bankId) => {
        let productInfo;
        const productId = state.parameters[-1]?.value;
        if (productId) {
            const products = state.bankAdvertises[bankId]?.products[productId];
            if (products) {
                const sortProducts = [...products].sort((a, b) => {
                    const paramsLength = (item) => {
                        return Object.values(item.params).filter((el) => el.length).length;
                    };
                    return paramsLength(a) < paramsLength(b) ? 1 : -1;
                });
                let index = 0;
                const findProduct = () => {
                    if (testExcludedParams(state.parameters, sortProducts[index].excluded_params) &&
                        testDopParams(state.parameters, sortProducts[index]?.params)) {
                        let bankInfo = sortProducts[index];
                        const btnName = bankInfo.button_name.length
                            ? bankInfo.button_name
                            : "Сайт";
                        const btn2Name = bankInfo.button_2_name.length
                            ? bankInfo.button_2_name
                            : bankInfo.button_2_type === 2
                                ? "Оставить заявку"
                                : "Действие";
                        productInfo = {
                            ...bankInfo,
                            button_name: btnName,
                            button_2_name: btn2Name,
                        };
                    }
                    else {
                        index++;
                        if (sortProducts[index]) {
                            findProduct();
                        }
                    }
                };
                findProduct();
            }
        }
        return productInfo;
    },
    getBankDefaultProduct: (state) => (bankId) => {
        return state.bankAdvertises[bankId]?.defaultProduct;
    },
};

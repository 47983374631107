import api from "@/shared/services/api";
import store from "@/store/app";
export default async function getMainInfo() {
    return api
        .get(process.env.VUE_APP_INFO_2_URL)
        .then((res) => {
        if (res.data.status === "success") {
            if (process.env.VUE_APP_IS_WIDGET === "true" &&
                !localStorage.getItem("bbankiac")) {
                localStorage.setItem("bbankiac", res.data.data.bbankiac);
            }
            store.commit("writeRegions", res.data.data.regions);
            store.commit("calculator/writeBanksLogos", res.data.data.bank_logos);
            store.commit("calculator/writeBankAdvertises", res.data.data.bank_advertises);
            store.commit("calculator/writeConstructionCompanyAdvertises", res.data.data.construction_company_advertises);
            if (res.data.data.bank_advertises) {
                let topBanks = {};
                Object.entries(res.data.data.bank_advertises).forEach((ad) => {
                    if (ad[1].is_gtop) {
                        topBanks[ad[0]] = {
                            bank_id: ad[0],
                            tagline_for_top: ad[1].gtop_message,
                        };
                    }
                });
            }
        }
    })
        .catch((e) => {
        store.dispatch("openAlertError", e);
    });
}

export const testExcludedParams = (parameters, excludedParams) => {
    if (excludedParams) {
        const preparedExcludedParams = Object.entries(excludedParams).filter((el) => el[1].length);
        if (preparedExcludedParams && preparedExcludedParams.length) {
            const excluded = preparedExcludedParams.every((param) => {
                return (parameters[param[0]] && param[1].includes(parameters[param[0]].value));
            });
            if (excluded) {
                return false;
            }
        }
        else {
            return true;
        }
    }
    return true;
};
export const testDopParams = (parameters, dopParams) => {
    if (dopParams) {
        return Object.entries(dopParams)
            .filter((el) => el[1].length)
            .every((param) => {
            return !(parameters[param[0]] && !param[1].includes(parameters[param[0]].value));
        });
    }
    else {
        return true;
    }
};

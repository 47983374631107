var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-btn',{staticClass:"elevation-0",class:[
    _vm.light ? 'main-btn-light' : 'main-btn',
    _vm.small ? 'small' : '',
    _vm.white ? 'main-btn-white' : 'main-btn',
    _vm.black ? 'main-btn-black' : 'main-btn',
    _vm.orange ? 'main-btn-orange' : 'main-btn',
    { 'rounded-xl': !_vm.customStyles },
    { upperCase: _vm.upperCase },
  ],attrs:{"color":_vm.color,"height":_vm.height,"width":_vm.width,"max-width":_vm.maxWidth,"loading":_vm.loading,"disabled":_vm.disabled,"href":_vm.link,"target":_vm.link ? '_blank' : ''},on:{"mouseenter":function($event){return _vm.toggleIcon('hov')},"mouseleave":function($event){return _vm.toggleIcon('def')},"click":function($event){return _vm.$emit('click')},"mouseup":function($event){return _vm.$emit('mouseup', $event)}}},[(_vm.icon !== undefined)?_c('img',{ref:"icon",staticClass:"main-btn__img mr-4",attrs:{"src":_vm.icon.def,"alt":"icon"}}):_vm._e(),(_vm.mdIcon !== undefined)?_c('v-icon',{staticClass:"pr-2"},[_vm._v(_vm._s(_vm.mdIcon))]):_vm._e(),_vm._v(" "+_vm._s(_vm.text)+" "),(_vm.afterMdIcon !== undefined)?_c('v-icon',[_vm._v(_vm._s(_vm.afterMdIcon))]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import Vue from "vue";
import Vuex from "vuex";
import { state } from "./state";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { getters } from "./getters";
import { user } from "../modules/user";
import { account } from "../modules/account";
import { calculator } from "../modules/calculator";
import { order } from "../modules/order";
import { checks } from "../modules/checks";
import { statistic } from "../modules/statistic";
import { common } from "@/store/modules/common";
import { onlineAssessment } from "@/store/modules/onlineAssessment";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);
export default new Vuex.Store({
    state,
    actions,
    mutations,
    getters,
    modules: {
        user,
        calculator,
        order,
        account,
        checks,
        statistic,
        onlineAssessment,
        common,
    },
    plugins: [
        createPersistedState({
            key: "user-bs",
            paths: ["user.userLogged", "user.user", "user.showWelcomeModal"],
        }),
    ],
});

import api from "@/shared/services/api";
import { getStore } from "@/store";
export const actions = {
    getStatisticList({ commit, dispatch }, queryParams = "") {
        return api
            .get(process.env.VUE_APP_STATISTICS_SELECTIONS + queryParams)
            .then((res) => {
            commit("writeStatisticList", res.data.data);
            commit("writeGeneratingReport", res.data.data);
            commit("writeSelectionsCount", res.data.data);
        })
            .catch((e) => {
            dispatch("openAlertError", e, { root: true });
        });
    },
    bankRating({ commit, dispatch }) {
        return api
            .get(process.env.VUE_APP_BANK_TRANSITIONS_STATISTIC_RATING)
            .then((res) => {
            commit("writeBankRatingList", res.data.data);
        })
            .catch((e) => {
            dispatch("openAlertError", e);
            return Promise.reject(e);
        });
    },
    getBanksCount({ commit, dispatch }) {
        return api
            .get(process.env.VUE_APP_BANKS_COUNT)
            .then((res) => {
            commit("writeBanksCount", res.data.data.banks);
        })
            .catch((e) => {
            dispatch("openAlertError", e, { root: true });
        });
    },
    orderAnalytics({ dispatch }, data) {
        let dataForModal;
        return api
            .post(process.env.VUE_APP_ORDER_ANALYTICS, data)
            .then((res) => {
            dataForModal = {
                status: res.data.status,
                successText: "Заявка отправлена",
                errorMessage: res.data.message,
                isValidationErrors: false,
            };
            getStore().dispatch("common/openInfoModal", dataForModal);
            return res.data.status;
        })
            .catch((e) => {
            dataForModal = {
                status: "error",
                errorMessage: e?.response?.data?.errorMessage ?? "Данные не сохранены",
                isValidationErrors: e?.response?.data?.validationErrors?.length,
            };
            getStore().dispatch("common/openInfoModal", dataForModal);
            return e.response.data;
        });
    },
    getBanksNewsList(_, queryParams = "") {
        return api.get(process.env.VUE_APP_BANKS_NEW_LIST + queryParams);
    },
    statisticsInfo({ dispatch }) {
        return api.get(process.env.VUE_APP_STATISTICS_INFO).catch((e) => {
            dispatch("openAlertError", e);
            return Promise.reject(e);
        });
    },
};

import api from "@/shared/services/api";
import { Bank } from "@/calculator/shared/Classes/BankClass";
import promoCalculation from "@/shared/scripts/PromoCalculation";
import getInsuranceInfo from "@/shared/requests/InsuranceRequest";
import { getStore } from "@/store";
import duringPromoCalc, { getDiffRate, } from "@/shared/scripts/duringPromoCalculation";
export default {
    async getAddParams({ commit, dispatch }) {
        let url = process.env.VUE_APP_ADD_PARAM_URL;
        if (process.env.VUE_APP_IS_WIDGET === "false" &&
            !getStore().state.user.userLogged) {
            url = process.env.VUE_APP_GUEST_ADD_PARAM_URL;
        }
        return api
            .get(url)
            .then((res) => {
            let data = res.data.data;
            commit("writeAdditionalParametersCategories", data);
        })
            .catch((e) => {
            dispatch("openAlertError", e, { root: true });
        });
    },
    getBankPreview({ state, commit, dispatch }) {
        const parameters = state.parameters;
        if (parameters[387] === undefined) {
            return;
        }
        const value = parameters[387].value;
        const levels = [0, 0.1, 0.2, 0.3, 0.5, 0.8, 0.9];
        let bankPreviewIds = state.bankPreviewIds;
        let initialFeeLevels = [];
        levels.forEach((lvl) => {
            initialFeeLevels.push(lvl * value);
        });
        bankPreviewIds.forEach((id) => {
            let bankPreview = {};
            api
                .post(process.env.VUE_APP_BANKS_PREVIEW, {
                parameters: Object.values(parameters),
                steps: initialFeeLevels,
            }, { params: { id } })
                .then((res) => {
                bankPreview.id = id;
                bankPreview.value =
                    process.env.VUE_APP_IS_WIDGET === "true" ? res.data : res.data.data;
            })
                .catch((e) => {
                bankPreview.id = undefined;
                dispatch("openAlertError", e, { root: true });
            })
                .finally(() => {
                commit("writeBankPreviewValues", bankPreview);
                commit("toggleLoadingBankPreview", false);
            });
        });
    },
    writePromotions({ commit, dispatch }, data) {
        if (data !== undefined) {
            Object.entries(data).forEach((bank) => {
                const promos = bank[1];
                Object.entries(promos).forEach((promo) => {
                    commit("writePromotions", {
                        bankId: bank[0],
                        promoId: promo[0],
                        promoData: promo[1],
                    });
                });
            });
        }
        commit("updateRequestPromotionCounter");
    },
    writeDuringConstructionPromo({ state, getters, commit }, data) {
        let saleRate = data.isConst
            ? +data.percent
            : Math.round((state.banks[data.bankId].minRate - +data.percent + data.diffRate) *
                100) / 100;
        let salePayment = getters.getPaymentByRate(saleRate / 100 / 12);
        let newOverpayment = salePayment * data.constructionTime +
            (+getters.getCalculatedPayment(data.bankId) *
                (getters.getCreditTerm - data.constructionTime) -
                getters.getFullCreditSum);
        commit("writeDuringConstructionPromo", {
            id: data.bankId,
            rate: saleRate,
            payment: salePayment,
            overpayment: newOverpayment,
        });
    },
    async getPromotions({ state, commit, dispatch }, banksIds) {
        let url = process.env.VUE_APP_PROMO_URL;
        if (process.env.VUE_APP_IS_WIDGET === "false" &&
            !getStore().state.user.userLogged) {
            url = process.env.VUE_APP_GUEST_PROMO_URL;
        }
        return api
            .post(url, {
            region: Object.values(state.parameters).find((obj) => obj.parameter === 0)?.value,
            banks: banksIds,
            parameters: Object.values(state.parameters),
        })
            .then((res) => {
            let data = res.data.data.promos;
            dispatch("writePromotions", data);
            if (!!data[Object.keys(state.banks)[0]]) {
                commit("toggleIsReduceRate", true);
            }
            /** Сохраненные акции в текущем шаблоне **/
            const selectedTemplatePromos = state.templates.selectedTemplate?.promos;
            /** Если есть применные акции из шаблона, то мы их пременяем **/
            if (selectedTemplatePromos) {
                selectedTemplatePromos.forEach((item) => {
                    if (state.banks[item.bankId]) {
                        item.promoIds.forEach((promo) => {
                            const data = state.banks[item.bankId].promo[promo];
                            if (data) {
                                dispatch("applyPromo", { ...data, applied: true });
                            }
                        });
                        if (item.salaryProject &&
                            state.banks[item.bankId].salaryProject.value > 0) {
                            state.banks[item.bankId].salaryProject.applied = true;
                            const data = {
                                id: item.bankId,
                                type: "salaryProject",
                                value: true,
                                bankSalary: state.banks[item.bankId].salaryProject,
                            };
                            dispatch("applyPromo", data);
                        }
                    }
                });
            }
        })
            .catch((e) => {
            getStore().dispatch("openAlertError", e, { root: true });
        });
    },
    async submitParameters({ commit, dispatch }) {
        commit("showLoader", true);
        return dispatch("sendParametersRequest").finally(() => {
            commit("showLoader", false);
        });
    },
    updateResult({ state, commit }, result = state.result) {
        let newResult = {
            count: result.count,
            rates: result.rates,
            payments: result.payments,
        };
        newResult.count = state.filteredBanks.length;
        newResult.rates = [
            state.filteredBanks.length > 0
                ? Math.min.apply(null, state.filteredBanks.map((el) => el.minRate))
                : 0,
            1,
        ];
        newResult.payments = [
            state.filteredBanks.length > 0
                ? Math.min.apply(null, state.filteredBanks.map((el) => {
                    const text = el.payment.replace(/\s/g, "");
                    // @ts-ignore
                    return Number(text.match(/\d+/g)[0]);
                }))
                : 0,
            1,
        ];
        commit("writeResultsWithIncome", newResult);
    },
    async sendParametersRequest({ state, commit, dispatch, getters }) {
        /** Значения из 1 вкладки калькулятора(Калькулятор) */
        const inputs = getters.calculatorInputs;
        const parameters = inputs
            .filter((input) => input.value !== undefined && input.changed)
            .map((input) => {
            /** Для параметра "Стаж" с id - 11 меняем максимальное значение */
            if (input.id === 11 && input.value > input.max) {
                return {
                    parameter: input.id,
                    value: 24,
                };
            }
            return {
                parameter: input.id,
                value: input.value,
            };
        });
        /** Значения из 2 вкладки калькулятора(Доп. параметры) */
        Object.keys(state.parameters).map((key) => {
            if (state.parameters[key].value !== undefined) {
                if (!parameters.some((el) => el.parameter === state.parameters[key].parameter)) {
                    parameters.push({
                        parameter: state.parameters[key].parameter,
                        value: state.parameters[key].value,
                    });
                }
            }
        });
        try {
            let url = process.env.VUE_APP_CALCULATOR_URL;
            if (process.env.VUE_APP_IS_WIDGET === "false" &&
                !getStore().state.user.userLogged) {
                url = process.env.VUE_APP_GUEST_CALCULATOR_URL;
            }
            const res = await api.post(url, {
                parameters: parameters,
            });
            let data = res.data.data;
            const result = data.result;
            result.light = data.light;
            result.isLight = data.isLight;
            if (state.isIncomeAccountable) {
                dispatch("updateResult");
            }
            commit("writeResults", result);
            if (result.isLight) {
                commit("updateTopBank", getters);
            }
            else {
                dispatch("getBanks");
            }
            if (state.clientVersion > 0 &&
                state.clientVersion < data.client_version) {
                getStore().commit("common/toggleShowUpdateInfoModal", true);
            }
            if (data.client_version !== 0) {
                commit("writeClientVersion", data.client_version);
            }
            new Promise(() => commit("applyRules", result.rules));
            return await data;
        }
        catch (e_1) {
            dispatch("openAlertError", e_1, { root: true });
        }
    },
    getBanks({ state, commit, dispatch, getters }) {
        const modules = state.modules;
        const result = state.result;
        if (result.count > 0) {
            const sendLogTransition = (actionId) => {
                getStore().dispatch("common/logTransition", {
                    bank_id: result.banks.map((el) => el.id),
                    action_id: actionId,
                });
            };
            let isTemplate = !!state.templates.selectedTemplate;
            if (isTemplate &&
                state.templates.selectedTemplate?.value?.length ===
                    Object.keys(state.parameters).length) {
                state.templates.selectedTemplate.value.forEach((el) => {
                    if (state.parameters[el.id].value !== el.value) {
                        isTemplate = false;
                    }
                });
            }
            if (isTemplate) {
                sendLogTransition(36);
            }
            else if (!state.secondFormHasFilledValues) {
                sendLogTransition(19);
            }
            else if (!Object.keys(state.checkedAdditionalParameters).length) {
                sendLogTransition(20);
            }
            else {
                sendLogTransition(21);
            }
            commit("toggleLoadingBankPreview", true);
            dispatch("getBankPreview");
            if (modules.banksList) {
                commit("toggleIsReduceRate", false);
                commit("writeTypesLoan", result.organizationTypeList);
                dispatch("writeBanks", result.banks);
                commit("writeShowRewardUser", !!result.show_reward_user);
                commit("writeShowPreferencesUser", !!result.show_preferences_user);
                const banksIds = result.banks
                    .filter((bank) => !bank.comboRate)
                    .map((bank) => bank.id);
                if (modules.promos) {
                    commit("toggleLoadingPromos", true);
                    dispatch("getPromotions", banksIds).finally(() => {
                        commit("toggleLoadingPromos", false);
                    });
                    commit("updateChoosedBanks", banksIds);
                }
                commit("updateTopBank", getters);
            }
        }
        else {
            commit("cleanBankPreview");
        }
    },
    updateCategory({ state, commit }, updatedCategory) {
        const categoryIndex = state.categories.findIndex((category) => category.id === updatedCategory.id);
        updatedCategory.inputs.forEach((input, i) => {
            const newInput = !state.categories[categoryIndex].inputs.some((el) => el.id === input.id);
            if (newInput) {
                commit("pushInput", { input, categoryIndex, index: i });
            }
        });
        state.categories[categoryIndex].inputs = state.categories[categoryIndex].inputs.filter((input) => {
            const remainingInput = updatedCategory.inputs.find((el) => el.id === input.id);
            if (remainingInput) {
                Object.assign(input, remainingInput);
                input.visible = true;
                /** Если старого значения поля нету в списке новых доступных значений, обнуляем его */
                if (input.value &&
                    input.values.length &&
                    !input.values.some((el) => el.id === input.value)) {
                    input.value = undefined;
                    input.changed = false;
                    commit("writeParameters", { id: input.id, value: undefined });
                }
                /** Если поле или опция есть в блокирующих параметрах то не отображаем */
                state.result.rules["Block"].forEach((rule) => {
                    if (input.id === rule.parameter) {
                        switch (rule["value_type"]) {
                            case "option":
                                rule.value.forEach((el) => {
                                    input.values = input.values.filter((value) => value.id !== el);
                                });
                                break;
                            case "parameter":
                                input.visible = false;
                                input.value = undefined;
                                input.changed = false;
                                commit("writeParameters", { id: input.id, value: undefined });
                                break;
                            default:
                                break;
                        }
                    }
                });
                return input;
            }
            else {
                commit("writeParameters", { id: input.id, value: undefined });
            }
        });
    },
    writeBanks({ state, getters, dispatch }, data) {
        state.banks = {};
        data.forEach((item) => {
            const newBank = new Bank(item);
            state.banks[item.id] = newBank;
            state.banks[item.id].organizationType =
                state.typesLoan[item.organizationType];
            const paymentNumber = getters.getCalculatedPayment(item.id);
            let payment = paymentNumber.toLocaleString();
            let income = getters.getCalculatedIncome(item.id).toLocaleString();
            if (newBank.minRate !== newBank.maxRate) {
                payment = ` до ${getters
                    .getCalculatedPayment(item.id, "maxRate")
                    .toLocaleString()}`;
                income = ` до ${getters
                    .getCalculatedIncome(item.id, "maxRate")
                    .toLocaleString()}`;
            }
            let creditSum = getters.inputsValues[151];
            if (getters.inputsValues[416]) {
                creditSum += getters.inputsValues[416];
            }
            if (getters.inputsValues[425]) {
                creditSum += getters.inputsValues[425];
            }
            const overpayment = paymentNumber * getters.inputsValues[153] - creditSum;
            state.banks[item.id].payment = payment;
            state.banks[item.id].income = income === Infinity ? "" : income;
            state.banks[item.id].overpayment = overpayment;
        });
        dispatch("updateInsuranceResult");
    },
    clearBanks({ state, commit }) {
        state.banks = {};
        commit("updateChoosedBanks", []);
    },
    deleteInsuranceResult({ commit }, polisOnlineCode) {
        commit("deleteInsuranceResult", polisOnlineCode);
        commit("deleteInsuranceParameters", polisOnlineCode);
    },
    updateInsuranceResult({ state, dispatch }) {
        const insuranceResultCopy = [...state.insuranceResult];
        insuranceResultCopy.forEach((result) => {
            if (state.banks[result.bankId]) {
                /* Обновление данных по страховым тарифам для выбранных банков*/
                dispatch("getInsuranceRates", {
                    bankId: result.bankId,
                    polisOnlineCode: result.id,
                    hasGas: state.insuranceParameters[result.id]?.["currentHasGas"] ?? false,
                });
            }
            else {
                dispatch("deleteInsuranceResult", result.id);
            }
        });
    },
    updateBanksPaymentAndIncome({ state, getters }) {
        Object.values(state.banks).forEach((bank) => {
            let creditSum;
            if (getters.getIsConsumerCredit) {
                creditSum = getters.inputsValues[18];
            }
            else {
                creditSum = getters.inputsValues[151];
                if (getters.inputsValues[416]) {
                    creditSum += getters.inputsValues[416];
                }
                if (getters.inputsValues[425]) {
                    creditSum += getters.inputsValues[425];
                }
            }
            const paymentNumber = getters.getCalculatedPayment(bank.id);
            let payment = paymentNumber.toLocaleString();
            let income = getters.getCalculatedIncome(bank.id).toLocaleString();
            let creditTerm;
            if (getters.getIsConsumerCredit) {
                creditTerm = getters.inputsValues[17];
            }
            else {
                creditTerm =
                    getters.inputsValues[153] !== undefined
                        ? getters.inputsValues[153]
                        : 0;
            }
            let overpayment = (paymentNumber * creditTerm -
                creditSum).toLocaleString();
            if (bank.minRate !== bank.maxRate) {
                const calculatedPayment = getters.getCalculatedPayment(bank.id, "maxRate");
                payment = ` до ${calculatedPayment.toLocaleString()}`;
                income = ` до ${getters
                    .getCalculatedIncome(bank.id, "maxRate")
                    .toLocaleString()}`;
                const overpaymentMaxRate = calculatedPayment * creditTerm - creditSum;
                overpayment = ` до ${overpaymentMaxRate.toLocaleString()}`;
            }
            bank.payment = payment;
            bank.income = income;
            bank.overpayment = overpayment;
        });
    },
    updateDataRateReduction({ commit, getters }, data) {
        const paymentNumber = getters.getCalculatedPayment(data.id, "minDiscountRate");
        commit("writePaymentRateReduction", {
            id: data.id,
            paymentNumber: paymentNumber,
        });
        const income = getters.getCalculatedIncome(data.id, "minDiscountRate");
        commit("writeIncomeRateReduction", {
            id: data.id,
            incomeRateReduction: income,
        });
        const creditTerm = getters.inputsValues[153];
        commit("writeOverpaymentRateReduction", {
            id: data.id,
            paymentNumber: paymentNumber,
            creditTerm: creditTerm,
            creditSum: data.creditSum,
        });
    },
    updateFilteredBanks({ state, dispatch }, filteredBanks) {
        state.filteredBanks = filteredBanks;
        const result = { ...state.resultWithIncome };
        dispatch("updateResult", result);
    },
    applyOptionType({ state, dispatch }, data) {
        const bank = state.banks[data.id];
        bank[data.type].applied = data.value;
        if (data.type === "myPreferences") {
            if (bank[data.type].applied) {
                bank.promoActive += 1;
            }
            else {
                bank.promoActive -= 1;
            }
        }
        if (data.type === "salaryProject") {
            return dispatch("applyPromo", data);
        }
    },
    applyPromo({ state, dispatch, commit, getters }, promo) {
        let bank = {};
        if (promo.type === "salaryProject") {
            /** Банк, которому принадлежит акция */
            bank = state.banks[promo.id];
        }
        else {
            bank = state.banks[promo["bank_id"]];
        }
        let applyPromoError = "";
        const usedManualPromos = []; // активные ручные акции
        Object.keys(bank.promo).forEach((promoID) => {
            if (bank.promo[promoID].applied &&
                !bank.promo[promoID].during_construction) {
                usedManualPromos.push(Number(promoID));
            }
        });
        let currentAppliedPromo = 0;
        if (promo.type !== "salaryProject") {
            currentAppliedPromo = promo.id;
            if (bank.promo[promo.id].applied &&
                !bank.promo[promo.id].during_construction) {
                let index = usedManualPromos.indexOf(Number(promo.id));
                if (index !== -1) {
                    usedManualPromos.splice(index, 1);
                }
            }
            else {
                usedManualPromos.push(Number(promo.id));
            }
        }
        const salaryProjectDiscount = bank.salaryProject.applied
            ? bank.salaryProject.value
            : 0;
        let result;
        if (promo.type !== "salaryProject" &&
            bank.allPromo[currentAppliedPromo].during_construction) {
            result = duringPromoCalc(bank.allPromo, currentAppliedPromo);
        }
        else {
            const promoWithoutDuring = { ...bank.allPromo };
            const duringPromoList = {};
            Object.entries(promoWithoutDuring).forEach((promo) => {
                if (promo[1].during_construction) {
                    if (promoWithoutDuring[promo[0]].applied) {
                        duringPromoList[promo[0]] = promoWithoutDuring[promo[0]];
                    }
                    delete promoWithoutDuring[promo[0]];
                }
            });
            result = promoCalculation(bank.baseRate, promoWithoutDuring, usedManualPromos, currentAppliedPromo, salaryProjectDiscount);
            result.promosList = { ...result.promosList, ...duringPromoList };
        }
        bank.promoActive = 0;
        if (bank.myPreferences.applied || bank.preferences.applied) {
            bank.promoActive += 1;
        }
        const appliedBanks = Object.keys(result.promosList);
        let duringConstructionPromo = {
            percent: 0,
            constructionTime: 0,
            isConst: false,
            diffRate: 0,
        };
        Object.keys(bank.allPromo).forEach((key) => {
            bank.allPromo[key]["applied"] = appliedBanks.includes(key);
        });
        Object.keys(bank.promo).forEach((key) => {
            if (appliedBanks.includes(key)) {
                bank.promo[key]["applied"] = true;
                bank.promoActive += 1;
                if (bank.promo[key]["during_construction"]) {
                    duringConstructionPromo = {
                        percent: +bank.promo[key]["percent"],
                        constructionTime: +bank.promo[key]["construction_time"],
                        isConst: +bank.promo[key].type_id === 2,
                        diffRate: getDiffRate(bank.promo, bank.promo[key].incompatibilities),
                    };
                }
            }
            else {
                bank.promo[key]["applied"] = false;
            }
        });
        if (bank.currentPaidRateReduction &&
            Object.keys(bank.currentPaidRateReduction)?.length) {
            commit("updateStatusCurrentPaidRateReduction", {
                id: bank.id,
                active: getters.getIncompatibilityReducedRateWithPromos(bank.currentPaidRateReduction, bank),
            });
        }
        if (appliedBanks.includes("salaryProject")) {
            bank.promoActive += 1;
            bank["salaryProject"].applied = true;
        }
        else {
            bank["salaryProject"].applied = false;
        }
        if (result.rate) {
            if (bank.maxRate === bank.minRate) {
                bank.maxRate = Number(result.rate);
                bank.minRate = Number(result.rate);
            }
            else {
                bank.maxRate -= bank.minRate - Number(result.rate);
                bank.minRate = Number(result.rate);
            }
        }
        if (duringConstructionPromo.percent > 0) {
            dispatch("writeDuringConstructionPromo", {
                bankId: bank.id,
                ...duringConstructionPromo,
            });
        }
        else {
            commit("resetDuringConstructionPromo", {
                id: bank.id,
            });
        }
        applyPromoError = result.error;
        return applyPromoError;
    },
    getInsuranceRates({ state, commit }, data) {
        const id = data.polisOnlineCode;
        const bankId = data.bankId;
        const isGas = data.hasGas ? "hasGas" : "notHasGas";
        let lastInsuranceParameters = {
            ...state.insuranceParameters[id],
        };
        commit("writeInsuranceParameters", data);
        if (JSON.stringify(state.insuranceParameters[id][isGas]) !==
            JSON.stringify(lastInsuranceParameters[isGas]) ||
            state.insuranceResult.find((el) => el.id === id)?.status[isGas] ===
                "error") {
            commit("writeInsuranceResult", {
                bankId: bankId,
                polisOnlineCode: id,
                result: [],
                isGas: isGas,
                status: "loading",
            });
            getInsuranceInfo(state.insuranceParameters[id][isGas]).then((data) => {
                if (data === "error") {
                    commit("writeInsuranceResult", {
                        bankId: bankId,
                        polisOnlineCode: id,
                        result: [],
                        isGas: isGas,
                        status: "error",
                    });
                }
                else if (data) {
                    commit("writeInsuranceResult", {
                        bankId: bankId,
                        polisOnlineCode: id,
                        result: data,
                        isGas: isGas,
                        status: "success",
                    });
                }
                else {
                    commit("writeInsuranceResult", {
                        bankId: bankId,
                        polisOnlineCode: id,
                        result: [],
                        isGas: isGas,
                        status: "success",
                    });
                }
            });
        }
    },
    createCalcTemplate({ state, getters, dispatch }, title) {
        const promos = getters.getAppliedPromos;
        let parameters = [];
        /** Значения из 2 вкладки калькулятора(Доп. параметры) */
        Object.keys(state.parameters).map((key) => {
            if (state.parameters[key].value !== undefined) {
                if (!parameters.some((el) => el.parameter === state.parameters[key].parameter)) {
                    parameters.push({
                        id: state.parameters[key].parameter,
                        value: state.parameters[key].value,
                    });
                }
            }
        });
        const templateData = {
            value: parameters,
            title: title,
            promos: promos,
        };
        let dataForModal;
        return api
            .post(process.env.VUE_APP_CALCULATOR_TEMPLATE_CREATE, templateData)
            .then((res) => {
            const newTemplate = {
                id: res.data.data.id,
                title: title,
                value: parameters,
                promos: promos,
                isCommon: false,
            };
            getStore().commit("calculator/createTemplate", newTemplate);
            dataForModal = {
                status: res.data.status,
                successText: "Шаблон сохранен",
                isValidationErrors: false,
            };
            getStore().dispatch("common/openInfoModal", dataForModal);
            return res.data.status;
        })
            .catch((e) => {
            dataForModal = {
                status: "error",
                errorMessage: e?.response?.data?.errorMessage ?? "Шаблон не сохранен",
                isValidationErrors: e?.response?.data?.validationErrors?.length,
            };
            getStore().dispatch("common/openInfoModal", dataForModal);
            return e.response.data;
        });
    },
};
